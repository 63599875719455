const gtmDebug = false

export class GTMProvider {
  constructor(app) {
    this.provide = app.$gtm
  }

  typeClearEvent = () => {
    this.provide.push({ type: undefined })
  }

  addToCartEvent = ({ ecommerce }) => {
    this.provide.push({
      event: 'addToCart',
      ecommerce,
    })

    const ga4e = {
      event: 'ga4_add_to_cart',
      currency: ecommerce.currencyCode,
      items: ecommerce.add.products.map((item) => ({
        item_id: item.id,
        item_name: item.name,
        item_category: item.category,
        price: item.price,
        quantity: item.quantity,
      })),
      value: ecommerce.add.products.reduce((total, item) => total + item.price, 0),
    }
    if (gtmDebug) console.log('addToCartEvent', JSON.stringify(ga4e), ecommerce)
    this.provide.push(ga4e)
  }

  applyVoucherEvent = (value) => {
    this.provide.push({
      event: 'apply_voucher',
      type: 'airalo_ga_event',
      value,
    })

    const ga4e = {
      event: 'ga4_apply_voucher',
      value: value.value,
      currency: value.currency,
      virtual_currency_name: 'voucher',
      coupon: value.coupon,
    }
    if (gtmDebug) console.log('applyVoucherEvent', JSON.stringify(ga4e), value)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  beginCheckoutEvent = (value) => {
    this.provide.push({
      event: 'begin_checkout',
      type: 'airalo_ga_event',
      value,
    })

    const ga4e = {
      event: 'ga4_begin_checkout',
      currency: value.currency,
      items: [
        {
          item_id: value.item_id,
          item_name: value.item_name,
          price: value.value,
          quantity: 1,
        },
      ],
      value: value.value,
      transaction_id: value.transaction_id,
    }
    if (gtmDebug) console.log('beginCheckoutEvent', JSON.stringify(ga4e), value)
    this.provide.push(ga4e)
  }

  countrySearchedEvent = (keyword) => {
    this.provide.push({
      event: 'country_searched',
      type: 'airalo_ga_event',
      value: { keyword },
    })

    const ga4e = {
      event: 'ga4_country_searched',
      value: keyword,
    }
    if (gtmDebug) console.log('countrySearchedEvent', JSON.stringify(ga4e), keyword)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  downloadBannerEvent = ({ event, app_store }) => {
    this.provide.push({
      event: `download_banner_${event}`,
      type: 'airalo_ga_event',
      value: { app_store },
    })

    const ga4e = {
      event: `ga4_download_banner`,
      type: `download_banner_${event}`,
      value: app_store,
    }
    if (gtmDebug) console.log('downloadBannerEvent', JSON.stringify(ga4e), event, app_store)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  esimActionsViewEvent = ({ sim_slug, sim_iccid }) => {
    this.provide.push({
      event: 'esim_actions_viewed',
      type: 'airalo_ga_event',
    })

    const ga4e = {
      event: 'ga4_esim_actions_viewed',
      type: 'esim_actions_viewed',
      value: `${sim_slug} - ${sim_iccid}`,
    }
    if (gtmDebug) console.log('esimActionsViewEvent', JSON.stringify(ga4e), sim_slug, sim_iccid)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  esimArchivedEvent = ({ sim_slug, sim_iccid }) => {
    this.provide.push({
      event: 'esim_archived',
      type: 'airalo_ga_event',
    })

    const ga4e = {
      event: 'ga4_esim_archived',
      type: 'esim_archived',
      value: `${sim_slug} - ${sim_iccid}`,
    }
    if (gtmDebug) console.log('esimArchivedEvent', JSON.stringify(ga4e), sim_slug, sim_iccid)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  esimDeletedEvent = ({ sim_slug, sim_iccid }) => {
    this.provide.push({
      event: 'esim_deleted',
      type: 'airalo_ga_event',
    })

    const ga4e = {
      event: 'ga4_esim_deleted',
      type: 'esim_deleted',
      value: `${sim_slug} - ${sim_iccid}`,
    }
    if (gtmDebug) console.log('esimDeletedEvent', JSON.stringify(ga4e), sim_slug, sim_iccid)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  esimPurchaseEvent = (value) => {
    this.provide.push({
      event: 'esim_purchase',
      type: 'airalo_ga_event',
      value,
    })

    const ga4e = {
      event: 'ga4_esim_purchase',
      currency: value.currency,
      transaction_id: value.transaction_id,
      value: value.value,
      coupon: value.coupon || '',
      flier_number: value.flier_number || undefined,
      items: [
        {
          item_id: value.item_id,
          item_name: value.item_name,
          price: value.value,
          quantity: 1,
        },
      ],
      purchase_type: 'esim',
    }
    if (gtmDebug) console.log('esimPurchaseEvent', JSON.stringify(ga4e), value)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  esimRenamedEvent = ({ sim_slug, sim_iccid, sim_new_name }) => {
    this.provide.push({
      event: 'esim_renamed',
      type: 'airalo_ga_event',
    })

    const ga4e = {
      event: 'ga4_esim_renamed',
      type: 'esim_renamed',
      value: `${sim_slug} - ${sim_iccid} ("${sim_new_name}")`,
    }
    if (gtmDebug) console.log('esimRenamedEvent', JSON.stringify(ga4e), sim_slug, sim_iccid, sim_new_name)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  esimRestoredEvent = ({ sim_slug, sim_iccid }) => {
    this.provide.push({
      event: 'esim_restored',
      type: 'airalo_ga_event',
    })

    const ga4e = {
      event: 'ga4_esim_restored',
      type: 'esim_restored',
      value: `${sim_slug} - ${sim_iccid}`,
    }
    if (gtmDebug) console.log('esimRestoredEvent', JSON.stringify(ga4e), sim_slug, sim_iccid)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  firstTimePurchaseEvent = (packageDetails) => {
    this.provide.push({
      event: 'first_time_purchase',
      type: 'airalo_ga_event',
      value: packageDetails,
    })

    const ga4e = {
      event: 'ga4_first_time_purchase',
      currency: packageDetails.currency,
      transaction_id: packageDetails.transaction_id,
      value: packageDetails.value,
      purchase_type: 'first-time',
      items: [
        {
          item_id: packageDetails.item_id,
          item_name: packageDetails.item_name,
          price: packageDetails.value,
          quantity: 1,
        },
      ],
    }
    if (gtmDebug) console.log('firstTimePurchaseEvent', JSON.stringify(ga4e), packageDetails)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  googleSearchActionEvent = (query) => {
    this.provide.push({
      event: 'google_search_action_to_the_site',
      type: 'airalo_ga_event',
      value: { query },
    })

    const ga4e = {
      event: 'ga4_google_search_action_to_the_site',
      value: query,
    }
    if (gtmDebug) console.log('googleSearchActionEvent', JSON.stringify(ga4e), query)
    this.provide.push(ga4e)
  }

  languageChangedEvent = ({ language_id, language_name }) => {
    this.provide.push({
      event: 'language_changed',
      type: 'airalo_ga_event',
      value: { language: language_id },
    })

    const ga4e = {
      event: 'ga4_language_changed',
      item_list_name: 'languages',
      items: [
        {
          item_id: language_id,
          item_name: language_name,
        },
      ],
    }
    if (gtmDebug) console.log('languageChangedEvent', JSON.stringify(ga4e), language_id, language_name)
    this.provide.push(ga4e)
  }

  loginEvent = (value) => {
    this.provide.push({
      event: 'login',
      type: 'airalo_ga_event',
      value,
    })

    const ga4e = {
      event: 'ga4_login',
      value: value.method,
    }
    if (gtmDebug) console.log('loginEvent', JSON.stringify(ga4e), value)
    this.provide.push(ga4e)
  }

  myesimsArchivedViewEvent = () => {
    this.provide.push({
      event: 'myesims_archived_view',
      type: 'airalo_ga_event',
    })

    const ga4e = {
      event: 'ga4_myesims_view',
      type: 'myesims_view',
      value: 'archived',
    }
    if (gtmDebug) console.log('myesimsArchivedViewEvent', JSON.stringify(ga4e))
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  myesimsPageViewEvent = () => {
    this.provide.push({
      event: 'myesims_page_view',
      type: 'airalo_ga_event',
    })

    const ga4e = {
      event: 'ga4_myesims_view',
      type: 'myesims_view',
      value: 'current',
    }
    if (gtmDebug) console.log('myesimsPageViewEvent', JSON.stringify(ga4e))
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  orderTransactionEcommerce = ({ ecommerce }) => {
    this.provide.push({ event: 'transaction', ecommerce })

    const ga4e = {
      event: 'ga4_transaction',
      ecommerce,
    }
    if (gtmDebug) console.log('orderTransactionEcommerce', JSON.stringify(ga4e), ecommerce)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  purchaseEvent = ({ eventPostfix, value }) => {
    this.provide.push({
      event: eventPostfix.length > 0 ? `purchase_${eventPostfix}` : 'purchase',
      type: 'airalo_ga_event',
      value,
    })

    const ga4e = {
      event: 'ga4_purchase',
      currency: value.currency,
      transaction_id: value.transaction_id,
      value: value.value,
      coupon: '',
      payment_method: eventPostfix || '',
      items: [
        {
          item_id: value.item_id,
          item_name: value.item_name,
          price: value.value,
          quantity: 1,
        },
      ],
    }
    if (gtmDebug) console.log('purchaseEvent', JSON.stringify(ga4e), eventPostfix, value)
    this.provide.push(ga4e)
  }

  redeemedAirmoneyVoucherEvent = (value) => {
    this.provide.push({
      event: 'redeemed_airmoney_voucher',
      type: 'airalo_ga_event',
      value,
    })

    const ga4e = {
      event: 'ga4_redeemed_airmoney_voucher',
      value: value.value,
      currency: value.currency,
      virtual_currency_name: 'voucher',
      coupon: value.voucher,
    }
    if (gtmDebug) console.log('redeemedAirmoneyVoucherEvent', JSON.stringify(ga4e), value)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  referralCodeAppliedEvent = (value) => {
    this.provide.push({
      event: 'referral_code_applied',
      type: 'airalo_ga_event',
      value,
    })

    const ga4e = {
      event: 'ga4_referral_code_applied',
      value: 3,
      currency: 'USD',
      virtual_currency_name: 'referral',
      coupon: value.code,
    }
    if (gtmDebug) console.log('referralCodeAppliedEvent', JSON.stringify(ga4e), value)
    this.provide.push(ga4e)
  }

  referralShareOptionTappedEvent = (share_option) => {
    this.provide.push({
      event: 'referral_share_option_tapped',
      type: 'airalo_ga_event',
      value: { share_option },
    })

    const ga4e = {
      event: 'ga4_referral_share_option_tapped',
      type: 'social_share',
      value: share_option,
    }
    if (gtmDebug) console.log('referralShareOptionTappedEvent', JSON.stringify(ga4e), share_option)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  signupEvent = (value) => {
    this.provide.push({
      event: 'sign_up',
      type: 'airalo_ga_event',
      value,
    })

    const ga4e = {
      event: 'ga4_sign_up',
      value: value.method,
      type: 'completed',
    }
    if (gtmDebug) console.log('signupEvent', JSON.stringify(ga4e), value)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  signupStartedEvent = () => {
    this.provide.push({
      event: 'signup_started',
      type: 'airalo_ga_event',
    })

    const ga4e = {
      event: 'ga4_sign_up',
      value: '',
      type: 'started',
    }
    if (gtmDebug) console.log('signupStartedEvent', JSON.stringify(ga4e))
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  storeClickEvent = (app_store) => {
    this.provide.push({
      type: 'airalo_ga_event',
      event: 'download_on_the_store',
      value: {
        app_store,
      },
    })

    const ga4e = {
      event: 'ga4_download_on_the_store',
      type: 'download_on_the_store',
      value: app_store.app_store,
    }
    if (gtmDebug) console.log('storeClickEvent', JSON.stringify(ga4e), app_store)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  topupPurchaseEvent = (value) => {
    this.provide.push({
      event: 'topup_purchase',
      type: 'airalo_ga_event',
      value,
    })

    const ga4e = {
      event: 'ga4_topup_purchase',
      currency: value.currency,
      transaction_id: value.transaction_id,
      value: value.value,
      coupon: value.coupon || '',
      purchase_type: 'topup',
      items: [
        {
          item_id: value.item_id,
          item_name: value.item_name,
          price: value.value,
          quantity: 1,
        },
      ],
    }
    if (gtmDebug) console.log('topupPurchaseEvent', JSON.stringify(ga4e), value)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  transactionEcommerce = ({ ecommerce }) => {
    this.provide.push({ event: 'transaction', ecommerce })

    const ga4e = {
      event: 'ga4_transaction',
      ecommerce,
    }
    if (gtmDebug) console.log('transactionEcommerce', JSON.stringify(ga4e), ecommerce)
    this.provide.push(ga4e)
  }

  transactionEvent = (sim) => {
    const item = {
      name: sim.title,
      id: sim.slug,
      price: sim.price,
      category: 'eSIM',
    }

    this.provide.push({
      event: 'transaction',
      ecommerce: {
        currencyCode: 'USD',
        impressions: [item],
      },
    })
    this.provide.push({
      event: 'transaction',
      ecommerce: {
        detail: {
          products: [item],
        },
      },
    })

    const ga4e = {
      event: 'ga4_transaction',
      ecommerce: {
        currencyCode: 'USD',
        impressions: [item],
        detail: {
          products: [item],
        },
      },
    }
    if (gtmDebug) console.log('transactionEvent', JSON.stringify(ga4e), sim)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  viewItemEvent = (value) => {
    this.provide.push({
      event: 'view_item',
      type: 'airalo_ga_event',
      value,
    })

    const ga4e = {
      event: 'ga4_view_item',
      currency: value.currency,
      value: value.value,
      items: [
        {
          item_id: value.item_id,
          item_name: value.item_name,
        },
      ],
    }
    if (gtmDebug) console.log('viewItemEvent', JSON.stringify(ga4e), value)
    this.provide.push(ga4e)
  }

  viewItemListEvent = (value) => {
    this.provide.push({
      event: 'view_item_list',
      type: 'airalo_ga_event',
      value,
    })

    const ga4e = {
      event: 'ga4_view_item_list',
      item_list_id: value.item_list_id,
      item_list_name: value.item_list_name,
      items: [],
    }
    if (gtmDebug) console.log('viewItemListEvent', JSON.stringify(ga4e), value)
    this.provide.push(ga4e)

    this.typeClearEvent()
  }

  //  NOT CONVERTED INTO GA4

  accountDeletionRequestSent = () => {
    this.provide.push({
      event: 'account_deletion_request_sent',
      type: 'airalo_ga_event',
    })

    this.provide.push({ type: undefined })
  }

  buyButtonTappedEvent = (value) => {
    this.provide.push({
      event: 'buy_button_tapped',
      type: 'airalo_ga_event',
      value,
    })
    this.provide.push({
      type: undefined,
    })
  }

  chargedEvent = (value) => {}

  copyReferralCodeTappedEvent = () => {
    this.provide.push({
      event: 'copy_referral_code_tapped',
      type: 'airalo_ga_event',
    })
    this.provide.push({
      type: undefined,
    })
  }

  esimDataUsageEvent = (data) => {}

  gotoEkycDetailButtonTapped = () => {}

  logoutEvent = () => {
    this.provide.push({
      event: 'logout',
      type: 'airalo_ga_event',
    })
    this.provide.push({ type: undefined })
  }

  paymentPageViewEvent = () => {
    this.provide.push({ event: 'payment_page_view', type: 'airalo_ga_event' })
  }

  profilePageViewEvent = () => {
    this.provide.push({
      event: 'profile_page_view',
      type: 'airalo_ga_event',
    })
    this.provide.push({ type: undefined })
  }

  purchaseFailedEvent = () => {
    this.provide.push({
      event: 'purchase_failed',
      type: 'airalo_ga_event',
    })
    this.provide.push({ type: undefined })
  }

  referAndEarnTappedEvent = () => {
    this.provide.push({
      event: 'refer_and_earn_tapped',
      type: 'airalo_ga_event',
    })
    this.provide.push({
      type: undefined,
    })
  }

  referralBannerEvent = (screen_type) => {
    this.provide.push({
      event: 'referral_banner_tapped',
      type: 'airalo_ga_event',
      value: { screen_type },
    })
    this.provide.push({ type: undefined })
  }

  referralCodeUsedEvent = (value) => {
    this.provide.push({
      event: 'referral_code_used',
      type: 'airalo_ga_event',
      value,
    })

    this.provide.push({ type: undefined })
  }

  referralLearnMoreTappedEvent = () => {
    this.provide.push({
      event: 'referral_learn_more_tapped',
      type: 'airalo_ga_event',
    })
    this.provide.push({
      type: undefined,
    })
  }

  referralProgramEnrollEvent = (code) => {
    this.provide.push({
      event: 'referral_program_enroll',
      type: 'airalo_ga_event',
      value: { code },
    })
    this.provide.push({
      type: undefined,
    })
  }

  referralScreenViewedEvent = (code) => {
    this.provide.push({
      event: 'referral_screen_viewed',
      type: 'airalo_ga_event',
      value: { code },
    })
    this.provide.push({
      type: undefined,
    })
  }

  referralShowFullHistoryTappedEvent = () => {
    this.provide.push({
      event: 'referral_show_full_history_tapped',
      type: 'airalo_ga_event',
    })
    this.provide.push({
      type: undefined,
    })
  }

  referralTermsConditionsTappedEvent = () => {
    this.provide.push({
      event: 'referral_terms_conditions_tapped',
      type: 'airalo_ga_event',
    })
    this.provide.push({
      type: undefined,
    })
  }

  userInformationEvent = (userInfo) => {
    this.provide.push({
      event: 'userInformation',
      ...userInfo,
    })
    this.provide.push({ type: undefined })
  }

  viewShareEsimClickedEvent = () => {
    this.provide.push({
      event: 'view_share_esim_clicked',
      type: 'airalo_ga_event',
    })
    this.provide.push({ type: undefined })
  }

  renewalsUpdateEvent = (payload) => {
    this.provide.push({
      event: 'renewal_status',
      ...payload,
    })
    this.provide.push({ type: undefined })
  }

  testingEvent = () => {
    const ts = new Date().toISOString().split('.')[0].replace(/[^\d]/gi, '')
    const e = {
      event: 'test',
      value: `testing--${ts.substring(0, 8)}.${ts.substring(8)}`,
    }
    if (gtmDebug) console.log(JSON.stringify(e))
    this.provide.push(e)

    this.typeClearEvent()
  }
}
