var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--renewals-modal important-tips-how-loyalty-program-works"
  }, [_c('general-modal', {
    attrs: {
      "modal-id": "renewals-modal",
      "hasCloseButton": true,
      "modalView": _vm.modalView,
      "height": "unset"
    },
    on: {
      "onPressClose": _vm.onCloseModalClicked
    }
  }, [_c('ImportantTipsModal', {
    staticClass: "text-center",
    attrs: {
      "assets-type": "image",
      "nextTipCaption": _vm.$t('components.tooltip.next-button'),
      "lastTipCaption": _vm.$t('components.tooltip.got-it-button'),
      "tipsList": _vm.infoList,
      "preloader": _vm.preloader,
      "img-height": 300,
      "img-width": 300,
      "has-large-image": false,
      "has-horizontal-align": false
    },
    on: {
      "closeModalClicked": _vm.onCloseModalClicked
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }