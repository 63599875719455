export class ImpactProvider {
  constructor(app) {
    this.provide = window.ire
    this.app = app
  }
  sha1Generator = (email) => {
    const crypto = require('crypto')
    // START-NOSCAN
    const shasum = crypto.createHash('sha1')
    // END-NOSCAN
    shasum.update(email)

    return shasum.digest('hex')
  }
  userIdentifyEvent = () => {
    const user = this?.app?.$auth?.user

    if (user) {
      const customerId = user?.id
      const customerEmail = this.sha1Generator(user?.email)

      this.provide('identify', { customerId, customerEmail })
    }
  }
  typeClearEvent = () => {}
  storeClickEvent = (app_store) => {}
  profilePageViewEvent = () => {}
  userInformationEvent = (userInfo) => {
    this.userIdentifyEvent()
  }
  referralBannerEvent = (screen_type) => {}
  downloadBannerEvent = ({ event, app_store }) => {}
  firstTimePurchaseEvent = (packageDetails) => {}
  languageChangedEvent = ({ language_id }) => {}
  referralProgramEnrollEvent = (code) => {}
  purchaseFailedEvent = () => {}
  logoutEvent = () => {}
  viewItemListEvent = (value) => {}
  countrySearchedEvent = (keyword) => {}
  googleSearchActionEvent = (query) => {}
  buyButtonTappedEvent = (value) => {}
  viewItemEvent = (value) => {}
  transactionEvent = (sim) => {}
  myesimsPageViewEvent = () => {}
  myesimsArchivedViewEvent = () => {}
  esimActionsViewEvent = () => {}
  esimRenamedEvent = () => {}
  esimArchivedEvent = () => {}
  esimRestoredEvent = () => {}
  esimDeletedEvent = () => {}
  redeemedAirmoneyVoucherEvent = (value) => {}
  referralScreenViewedEvent = (code) => {}
  referAndEarnTappedEvent = () => {}
  referralTermsConditionsTappedEvent = () => {}
  referralShareOptionTappedEvent = () => {}
  copyReferralCodeTappedEvent = () => {}
  referralShowFullHistoryTappedEvent = () => {}
  referralLearnMoreTappedEvent = () => {}
  purchaseEvent = ({ eventPostfix, value }) => {}
  transactionEcommerce = ({ ecommerce, typeClear = true }) => {}
  orderTransactionEcommerce = ({ ecommerce, order, orderCode, typeClear = true }) => {
    const user = this?.app?.$auth?.user
    const getOrderPromoCode = () => {
      if (order.campaign?.type) {
        if (order.campaign.type === 'discount') return order.coupon.code
        if (order.campaign.type === 'referral') return order.referrer.code
      }
      return ''
    }
    const orderPromoCode = getOrderPromoCode()
    const orderDiscount = order.discount || 0
    const customerStatus = JSON.parse(localStorage.getItem('isFirstOrder')) ? 'New' : 'Returning'

    this.provide(
      'trackConversion',
      29336,
      {
        orderId: orderCode,
        customerId: user?.id,
        customerEmail: this.sha1Generator(user?.email),
        customerStatus,
        currencyCode: 'USD',
        orderPromoCode,
        orderDiscount,
        items: [
          {
            subTotal: order.total,
            category: 'eSIM',
            sku: order.package.id,
            quantity: 1,
            name: [order.sim.operator.title, order.package.title].join(' '),
          },
        ],
      },
      {
        verifySiteDefinitionMatch: true,
      }
    )
  }
  topupPurchaseEvent = (value) => {}
  esimPurchaseEvent = (value) => {}
  chargedEvent = (value) => {}
  viewShareEsimClickedEvent = () => {}
  loginEvent = (value) => {}
  signupEvent = (value) => {}
  referralCodeAppliedEvent = (value) => {}
  signupStartedEvent = () => {}
  paymentPageViewEvent = () => {}
  addToCartEvent = ({ ecommerce }) => {}
  beginCheckoutEvent = (value) => {}
  applyVoucherEvent = (value) => {}
  referralCodeUsedEvent = (value) => {}
  esimDataUsageEvent = (data) => {}
  accountDeletionRequestSent = () => {}
  gotoEkycDetailButtonTapped = () => {}
  renewalsUpdateEvent = () => {}
  testingEvent = () => {}
}
