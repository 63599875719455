<template>
  <div class="c--renewals-modal important-tips-how-loyalty-program-works">
    <general-modal
      modal-id="renewals-modal"
      :hasCloseButton="true"
      :modalView="modalView"
      height="unset"
      @onPressClose="onCloseModalClicked"
    >
      <ImportantTipsModal
        assets-type="image"
        class="text-center"
        :nextTipCaption="$t('components.tooltip.next-button')"
        :lastTipCaption="$t('components.tooltip.got-it-button')"
        :tipsList="infoList"
        :preloader="preloader"
        :img-height="300"
        :img-width="300"
        :has-large-image="false"
        :has-horizontal-align="false"
        @closeModalClicked="onCloseModalClicked"
      />
    </general-modal>
  </div>
</template>

<script>
  import ImportantTipsModal from '~/components/modals/important-tips'
  import GeneralModal from '~/components/modals/general-modal.vue'

  export default {
    components: {
      ImportantTipsModal,
      GeneralModal,
    },

    props: {
      viewModal: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        infoList: [
          {
            title: this.$t('components.renewals.whatsnew.first-title'),
            subtitle: this.$t('components.renewals.whatsnew.first-description'),
            imageUrl: '/assets/images/renewals/renewals-whatsnew-1.png',
          },
          {
            title: this.$t('components.renewals.whatsnew.second-title'),
            subtitle: this.$t('components.renewals.whatsnew.second-description'),
            imageUrl: '/assets/images/renewals/renewals-whatsnew-2.png',
          },
          {
            title: this.$t('components.renewals.whatsnew.third-title'),
            subtitle: this.$t('components.renewals.whatsnew.third-description'),
            imageUrl: '/assets/images/renewals/renewals-whatsnew-3.png',
          },
        ],

        modalView: false,
        preloader: true,
      }
    },

    methods: {
      async onCloseModalClicked() {
        this.$emit('onClickOk')
      },
    },

    watch: {
      viewModal(val) {
        if (val) {
          this.modalView = val
          setTimeout(() => {
            this.preloader = false
          }, 100)
        }
      },
    },
  }
</script>
