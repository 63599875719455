<template>
  <div>
    <header :class="['c--shared_header header', { 'd-none d-sm-block': isMyESIMSDetailPages }]">
      <div class="header-content d-flex align-items-center">
        <HeaderLogo :branding="branding" :is-checkout-page="isCheckoutPage" />

        <template
          v-if="(isCheckoutPage && deviceType.isDesktop && isAuthenticated) || (!isCheckoutPage && isAuthenticated)"
        >
          <div
            :class="{
              'ml-auto': $dir() === 'ltr',
              'mr-auto': $dir() === 'rtl',
            }"
          >
            <Airmoney />
          </div>
        </template>

        <div
          :class="[
            'header-navigation d-none',
            {
              'd-sm-block': !deviceType.isMobile && !deviceType.isTablet,
              'ml-auto': !isAuthenticated && $dir() === 'ltr',
              'mr-auto': !isAuthenticated && $dir() === 'rtl',
            },
          ]"
        >
          <nav>
            <ul
              v-if="isAuthenticated"
              :class="[
                'cf d-flex align-items-center flex-wrap justify-content-end',
                {
                  'border-l border-l-solid color-border-left-light pl-10 ml-10 pl-lg-20 ml-lg-20': $dir() === 'ltr',
                  'border-r border-r-solid color-border-right-light pr-10 mr-10 pr-lg-20 mr-lg-20': $dir() === 'rtl',
                },
              ]"
            >
              <li
                v-for="(item, index) in navigationItems.loggedIn"
                :key="`header-item-v2-${index}`"
                :class="{
                  'nav-item-with-sub-menu': item.subItems && item.subItems.length > 0,
                }"
                data-testid="account-menu"
              >
                <header-item-block :item="item" :is-slot="item.isSlot" :dir="$dir()">
                  <template v-if="item.name === 'user'">
                    <span
                      class="arrow-icon"
                      :class="{
                        'ml-10': $dir() === 'ltr',
                        'mr-10': $dir() === 'rtl',
                      }"
                    >
                      <span class="airalo-icon-arrow-down tw-text-2 airalo-icon-wrapper" />
                    </span>
                  </template>
                </header-item-block>

                <template v-if="item.subItems && item.subItems.length > 0">
                  <div class="header-sub-menu-wrapper">
                    <header-sub-menu :type="'small'" :list="item.subItems" @onClickItem="onClickSubMenuPreventItem" />
                  </div>
                </template>
              </li>
              <template v-if="!isError">
                <li @click="currenciesModalView = true">
                  <header-item-block :item="currencyItem" :dir="currencyItem.dir" />
                </li>
                <li @click="languagesModalView = true">
                  <header-item-block :item="languageItem" :dir="languageItem.dir" data-testid="language-header-btn" />
                </li>
              </template>
            </ul>
            <ul v-else class="cf d-flex align-items-center flex-wrap justify-content-end">
              <li
                v-for="(item, index) in navigationItems.loggedOut"
                :key="`header-item-v2-${index}`"
                :class="{
                  'nav-item-with-sub-menu': item.subItems && item.subItems.length > 0,
                }"
                :data-testid="`nav-item-${item.name ?? index}`"
              >
                <header-item-block :item="item" :is-slot="item.isSlot" :dir="$dir()">
                  <template v-if="['partner-with-us', 'about-us'].includes(item.name)">
                    <span
                      class="arrow-icon"
                      :class="{
                        'ml-10': $dir() === 'ltr',
                        'mr-10': $dir() === 'rtl',
                      }"
                    >
                      <i class="airalo-icon-arrow-down tw-text-2 airalo-icon-wrapper" />
                    </span>
                  </template>
                </header-item-block>

                <template v-if="item.subItems && item.subItems.length > 0">
                  <div class="header-sub-menu-wrapper">
                    <header-sub-menu :type="'small'" :list="item.subItems" @onClickItem="onClickSubMenuPreventItem" />
                  </div>
                </template>
              </li>
              <template v-if="!isError">
                <li @click="currenciesModalView = true">
                  <header-item-block :item="currencyItem" :dir="currencyItem.dir" />
                </li>
                <li @click="languagesModalView = true">
                  <header-item-block :item="languageItem" :dir="languageItem.dir" />
                </li>
              </template>
            </ul>
          </nav>
        </div>
      </div>
      <client-only>
        <log-out-modal
          :modal-view="isLogoutModalShown"
          :overlay-view="isLoggingOut"
          type="double"
          :text="$t('components.logout.title')"
          :button="{
            text: $t('global.cancel'),
            callbackName: 'closeLogOutModal',
            isClose: true,
          }"
          :button-second="{
            text: $t('components.logout.positive-button'),
            callbackName: 'logout',
            class: 'red',
          }"
          @onCloseModal="closeLogOutModal"
          @onClickButton="clickLogOutModalActions"
        />

        <currencies-modal
          :modal-view="currenciesModalView"
          :currencies="currencies"
          :title="$t('components.currencies.modal.title')"
          :search-input-placeholder="$t('placeholder.currencies.modal.currency')"
          :empty-text="$t('global.no-currency-available')"
          @onCloseCurrenciesModal="onCloseCurrenciesModal"
        />

        <languages-modal
          :modal-view="languagesModalView"
          :languages="languages"
          :title="$t('components.languages.modal.title')"
          :search-input-placeholder="$t('placeholder.languages.modal.language')"
          :empty-text="$t('global.no-language-available')"
          @onCloseLanguagesModal="onCloseLanguagesModal"
        />
      </client-only>
    </header>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Airmoney from '~/components/shared/header/airmoney.vue'
  import CurrenciesModal from '~/components/modals/currencies-modal'
  import HeaderItemBlock from '~/components/shared/header/item-block.vue'
  import UiBrandingTopline from '~/components/UI/Branding/Topline.vue'
  import HeaderSubMenu from '~/components/shared/header/sub-menu.vue'
  import LanguagesModal from '~/components/modals/languages-modal'
  import LogOutModal from '~/components/modals/prompt-simple.vue'
  import HeaderLogo from '@/components/UI/HeaderLogo.vue'

  export default {
    components: {
      Airmoney,
      CurrenciesModal,
      HeaderItemBlock,
      HeaderSubMenu,
      LanguagesModal,
      LogOutModal,
      UiBrandingTopline,
      HeaderLogo,
    },

    props: {
      isError: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        user: null,
        currenciesModalView: false,
        isLoggingOut: false,
        airmoney: '0.00',
        languagesModalView: false,
        resizeDebounce: null,
      }
    },
    computed: {
      ...mapGetters({
        branding: 'coBranding/getBranding',
        isCobranded: 'coBranding/getIsCoBranded',
      }),
      isAuthenticated() {
        return this.$store.getters.is_authenticated
      },

      deviceType() {
        return this.$store.getters.get_device_types
      },

      userInfo: {
        get() {
          return { ...this.$store.$auth.$state.user }
        },
      },

      userIdentitiesDefault: {
        get() {
          return this.$store.getters.get_user_identities_default
        },
      },

      isLogoutModalShown: {
        get() {
          return this.$store.getters.get_logout_modal_visibility
        },
        set(value) {
          this.$store.commit('set_logout_modal_visibility', value)
        },
      },

      navigationItems() {
        const items = {
          loggedIn: [
            {
              name: 'my-esims',
              image: {
                name: 'airalo-icon-myesim',
                class: true,
              },
              text: this.$t('components.main.navigation.my-esims'),
              url: '/my-esims',
              gtm: true,
            },
            {
              name: 'user',
              image: {
                name: 'airalo-icon-profile',
                class: true,
              },
              text: this.userInfo ? this.userInfo.name : '',
              isSlot: true,
              subItems: [
                {
                  text: this.$t('components.main.navigation.account-information'),
                  link: '/profile/settings',
                },
                {
                  text: this.$t('components.main.navigation.airmoney-loyalty-status'),
                  link: '/profile/airmoney',
                },
                {
                  text: this.$t('components.main.navigation.saved-cards'),
                  link: '/profile/credit-cards',
                },
                {
                  text: this.$t('components.main.navigation.referral'),
                  link: '/profile/referral',
                },
                {
                  text: this.$t('components.main.navigation.orders'),
                  link: '/profile/orders',
                },
                {
                  image: 'airalo-icon-log-out',
                  text: this.$t('components.main.navigation.logout'),
                  customClass: 'logout-text',
                  callback: {
                    event: 'click',
                    functionName: 'onCLickLogout',
                  },
                },
              ],
            },
          ],
          loggedOut: [
            ...(!this.isCoBranded
              ? [
                  {
                    name: 'partner-with-us',
                    text: this.$t('components.main.navigation.partner-with-us'),
                    isSlot: true,
                    subItems: [
                      {
                        text: this.$t('components.main.navigation.partner.with.us.airalo-partner-hub'),
                        link: '/partner-with-us/airalo-partner-hub',
                      },
                      {
                        text: this.$t('components.main.navigation.partner.with.us.affiliate-partners'),
                        link: '/partner-with-us/affiliate-partners',
                      },
                      {
                        text: this.$t('components.main.navigation.partner.with.us.distrubition-partners'),
                        link: '/partner-with-us/distrubition-partners',
                      },
                      {
                        text: this.$t('components.main.navigation.partner.with.us.api-partners'),
                        link: '/partner-with-us/api-partners',
                      },
                      {
                        text: this.$t('components.main.navigation.partner.with.us.airalo-for-corporates'),
                        link: '/partner-with-us/airalo-for-corporates',
                      },
                      // {
                      //   text: this.$t('components.main.navigation.partner.with.us.partner-login'),
                      //   link: 'https://partners.airalo.com/login',
                      //   external: true,
                      //   selfUrl: true,
                      // },
                    ],
                  },
                ]
              : []),
            {
              name: 'about-us',
              text: this.$t('components.main.navigation.about-us'),
              isSlot: true,
              subItems: [
                {
                  text: this.$t('components.main.navigation.about.us.about-airalo'),
                  link: '/about-us/about-airalo',
                },
                {
                  text: this.$t('components.main.navigation.about.us.impact'),
                  link: '/about-us/impact',
                },
                {
                  text: this.$t('components.main.navigation.about.us.newsroom'),
                  link: '/about-us/newsroom',
                },
                {
                  text: this.$t('components.main.navigation.about.us.media-center'),
                  link: '/about-us/media-center',
                },
                {
                  text: this.$t('components.main.navigation.about.us.our-values'),
                  link: '/about-us/our-values',
                },
              ],
            },
            {
              name: 'login',
              image: {
                name: 'airalo-icon-profile',
                class: true,
              },
              text: {
                separator: '/',
                texts: [
                  {
                    text: this.$t('components.main.navigation.login'),
                    url: {
                      name: 'auth-index-login',
                    },
                    gtm: true,
                  },
                  {
                    text: this.$t('components.main.navigation.register'),
                    url: {
                      name: 'auth-index-signup',
                    },
                    gtm: true,
                  },
                ],
              },
            },
          ],
        }

        if (this.$store.getters['firebaseFeatureFlags/get']?.flags?.['enable_corporate_mode'] === 'true') {
          items.loggedIn[1].subItems.splice(-1, 0, {
            text: this.$t('components.main.navigation.organizations'),
            link: '/profile/organizations',
          })
        }

        if (this.$auth.user && this.userIdentitiesDefault) {
          items.loggedIn[1].subItems.splice(1, 0, {
            text: this.$t('components.main.navigation.identity-authentication'),
            link: '/profile/identity-authentication/default',
          })
        }

        if (this.isCobranded) {
          Reflect.set(
            items,
            'loggedOut',
            items.loggedOut.filter((item) => item.name !== 'partner-with-us')
          )
        }

        return items
      },

      currentLang() {
        return this.$i18n.locales.find((v) => v.code === this.$i18n.locale)
      },

      currencyItem() {
        return {
          name: 'currency',
          image: null,
          text: this.$store.getters['multiCurrency/get_current_currency_symbol_code'],
          dir: this.currentLang.dir,
        }
      },

      languageItem() {
        const currentLang = this.$i18n.locales.find((v) => v.code === this.$i18n.locale)
        return {
          name: 'language',
          image: {
            name: 'airalo-icon-globe',
            class: true,
          },
          text: currentLang.native,
          dir: currentLang.dir,
        }
      },

      currencies() {
        return this.$store.getters['multiCurrency/get_available_currencies']
      },

      languages() {
        return this.$i18n.locales
      },

      isMyESIMSDetailPages() {
        if (this.isError) {
          return false
        } else if (this.getRouteBaseName()) {
          const getRouteBaseName = this.getRouteBaseName()
          return [
            'my-esims-detail-id',
            'my-esims-detail-id-installation',
            'my-esims-detail-id-installation-ios',
            'my-esims-detail-id-installation-android',
            'my-esims-detail-id-installation-ios-index',
            'my-esims-detail-id-installation-android-index',
            'my-esims-detail-id-installation-ios-index-qr',
            'my-esims-detail-id-installation-ios-index-manual',
            'my-esims-detail-id-installation-android-index-qr',
            'my-esims-detail-id-installation-android-index-manual',
          ].includes(getRouteBaseName)
        } else {
          return false
        }
      },

      isCheckoutPage() {
        return this.getRouteBaseName()
          ? [
              'esim-buy-id',
              'esim-buy-id-index',
              'esim-buy-id-index-topup-topup',
              'esim-buy-id-complete',
              'esim-buy-id-cancel',
              'esim-buy-fail',
              'order-id',
              'order-fail',
            ].includes(this.getRouteBaseName())
          : false
      },
    },
    methods: {
      onClickSubMenuPreventItem(e) {
        const callbackName = e.callback.functionName

        this[callbackName](e.callback)
      },

      onCLickLogout() {
        this.$store.commit('set_logout_modal_visibility', true)
      },

      closeLogOutModal() {
        this.$store.commit('set_logout_modal_visibility', false)
      },

      clickLogOutModalActions(e, data) {
        this[data.callbackName]()
      },

      onCloseCurrenciesModal() {
        this.currenciesModalView = false
      },

      onCloseLanguagesModal() {
        this.languagesModalView = false
      },

      async logout(e) {
        this.$utils.sendEvent('logoutEvent')

        this.isLoggingOut = true
        await this.$auth.logout()

        this.isLoggingOut = false
        this.closeLogOutModal()
        this.$router.push(this.localePath('/'))
      },

      handleAnimationStart(e) {
        if (e.animationName.includes('sub-menu-show')) {
          e.target.classList.add('did-fade-in')
        }
      },

      handleAnimationEnd(e) {
        if (e.animationName.includes('sub-menu-hide')) {
          e.target.classList.remove('did-fade-in')
        }
      },
    },

    mounted() {
      if (process.client) {
        document.addEventListener('animationstart', this.handleAnimationStart)
        document.addEventListener('animationend', this.handleAnimationEnd)

        this.$auth.user && this.$store.dispatch('fetch_user_identities_default')
      }
    },

    beforeDestroy() {
      if (window) {
        document.removeEventListener('animationstart', this.handleAnimationStart)
        document.removeEventListener('animationend', this.handleAnimationEnd)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .mr-3px {
    margin-right: 3px;
  }

  .ml-3px {
    margin-left: 3px;
  }

  ::v-deep .modal-dialog {
    max-width: 360px;
    margin: auto;
  }

  .header {
    background: #fff;
    width: 100%;
    padding: 0 30px;

    @media only screen and (max-width: 767px) {
      padding: 0 20px;
      background: #fff;
      box-shadow: 0 5px 10px 0 rgb(0 0 0 / 5%), 0 10px 30px 0 rgb(0 0 0 / 10%);
    }

    .home-logo {
      position: static;
      margin: auto;
      transform: none;
    }

    .airmoney-box {
      display: flex;
      align-items: flex-start;
      margin-left: 5px;

      &.mobile-box {
        margin-left: 0;
      }

      [dir='rtl'] & {
        margin-left: unset;
        margin-right: 5px;

        &.mobile-box {
          margin-left: unset;
          margin-right: 0;
        }
      }

      &-inner {
        display: flex;
        align-items: flex-start;
        margin-left: 5px;

        [dir='rtl'] & {
          margin-left: unset;
          margin-right: 5px;

          &.mobile-box {
            margin-left: unset;
            margin-right: 0;
          }
        }
      }

      &-title {
        color: #4a4a4a;
        font-size: 0.8125rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1.1538;
        margin-top: auto;
        margin-bottom: auto;
      }

      &-value {
        color: #4a4a4a;
        font-size: 1.1875rem;
        font-weight: 600;
        letter-spacing: -0.2px;
        line-height: 1.15789;

        &-sup {
          color: #4a4a4a;
          font-size: 0.6875rem;
          font-weight: 600;
          letter-spacing: 1px;
          line-height: 1.2727;
          top: -1px;
        }
      }
    }

    &-logo-link {
      display: inline-flex;

      @media only screen and (max-width: 1023px) {
        img {
          height: 30px;
        }
      }
    }

    .header-airmoney-on-mobile {
      display: none;

      @media only screen and (max-width: 767px) {
        display: flex;
      }
    }

    .mobile-logo-side-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @media only screen and (min-width: 768px) {
        display: none;
      }
    }

    &-content {
      margin: 0 auto;
      height: 100%;
      padding: 40px 0;
      max-width: 1120px;

      // @media (min-width: 1025px) and (max-width: 1119px) {
      //   width: 992px !important;
      // }

      @media only screen and (max-width: 1023px) {
        padding: 20px 0;
      }
    }

    &-navigation {
      //   margin-left: auto;

      //   [dir='rtl'] & {
      //     margin-left: unset;
      //     margin-right: auto;
      //   }

      @media only screen and (max-width: 767px) {
        display: none;
      }

      nav {
        ul {
          li {
            display: flex;
            align-items: center;
            border-right: 1px solid #dddddd;
            padding: 11px 12px;

            @media only screen and (max-width: 1023px) {
              padding-left: 10px;
              padding-right: 10px;
            }

            &:first-child {
              padding-left: 0;

              [dir='rtl'] & {
                border-right: none;
                padding-left: 20px;
                padding-right: 0;

                @media only screen and (max-width: 1023px) {
                  padding-left: 10px;
                }
              }
            }

            &:last-child {
              border-right: none;
              padding-right: 0;

              [dir='rtl'] & {
                border-right: 1px solid #dddddd;
                padding-left: 0;
                padding-right: 20px;

                @media only screen and (max-width: 1023px) {
                  padding-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .nav-item-with-sub-menu {
    position: relative;

    .arrow-icon {
      display: inline-flex;
      color: #4a4a4a;
      margin-top: 1px;

      .airalo-icon-wrapper {
        display: inline-flex;
        position: relative;
        transform: rotate(0deg);
        transition: transform 250ms linear;
      }
    }

    .header-sub-menu-wrapper {
      height: 0;
      opacity: 0;
      padding-top: 0;
      position: absolute;
      top: 100%;
      right: 0;
      overflow: hidden;
      z-index: 99;

      [dir='rtl'] & {
        right: unset;
        left: 0;
      }
    }

    &:hover {
      .arrow-icon {
        .airalo-icon-wrapper {
          display: inline-flex;
          transform: rotate(-180deg);

          [dir='rtl'] & {
            transform: rotate(180deg);
          }
        }
      }

      .header-sub-menu-wrapper {
        opacity: 1;
        transform: translateY(0px);
        height: unset;
        animation: sub-menu-show 250ms linear;
        padding-top: 10px;
        overflow: unset;
      }
    }

    &:not(:hover) {
      .header-sub-menu-wrapper.did-fade-in {
        height: 0px;
        animation: sub-menu-hide 250ms linear;
      }
    }
  }

  @keyframes sub-menu-show {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    1% {
      height: unset;
      padding-top: 10px;
      overflow: unset;
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes sub-menu-hide {
    0% {
      opacity: 1;
      transform: translateY(0px);
      height: unset;
      padding-top: 10px;
      overflow: unset;
    }

    99% {
      opacity: 0;
      transform: translateY(20px);
      padding-top: 10px;
      overflow: unset;
    }

    100% {
      opacity: 0;
      height: 0px;
    }
  }
</style>
