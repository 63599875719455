import Cookies from 'cookies'
import ClientSideCookies from 'js-cookie'

export default async ({ app, store, req, res, $axios }, inject) => {
  const cookies = process.server ? new Cookies(req, res) : ClientSideCookies

  let defaultLocale = 'en'

  let locale = app.i18n.locale

  if (!locale) {
    locale = defaultLocale

    if (cookies.get('airalo_browser_language')) {
      locale = cookies.get('airalo_browser_language')
    }

    if (app.i18n.locale) {
      locale = app.i18n.locale
    }
  }

  cookies.set('Airalo.locale', locale, {
    expires: new Date(Date.now() + 60 * 60 * 60 * 24 * 365),
    httpOnly: false,
  })

  $axios.defaults.headers.common['accept-language'] = locale

  app.i18n.onLanguageSwitched = async (_oldLocale, _newLocale) => {
    $axios.defaults.headers.common['accept-language'] = _newLocale
    if (process.client) {
      await store.dispatch('fetch_footer_countries_links')

      store.commit('set_cr_key')
    }
  }
  app.i18n.currentLanguageName = () => {
    return app.i18n.locales.find((x) => x.code === app.i18n.locale)?.native
  }

  app.nuxt.defaultTransition.beforeEnter = () => {
    app.i18n.finalizePendingLocaleChange()
  }

  app.router.options.scrollBehavior = async (to, from, savedPosition) => {
    if (to.name !== from.name) {
      await app.i18n.waitForPendingLocaleChange()
    }

    return savedPosition || { x: 0, y: 0 }
  }

  const dir = () => {
    return app.i18n.locales.find((x) => x.code === app.i18n.locale)?.dir
  }

  inject('dir', dir)
}
