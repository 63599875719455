export class ClevertapProvider {
  constructor(app) {
    this.app = app
    this.provide = window.clevertap
  }
  typeClearEvent = () => {}
  storeClickEvent = (app_store) => {}
  profilePageViewEvent = () => {
    this.provide.event.push('profile_page_view')
  }
  userInformationEvent = ({ userInfo }) => {
    this.provide.onUserLogin.push({
      Site: {
        Name: [userInfo.firstName, userInfo.lastName].join(' '), // String
        Identity: userInfo.identity,
        'MSG-email': false, // Disable email notifications
        'MSG-push': false, // Enable push notifications
        'MSG-sms': false, // Enable sms notifications
        'MSG-whatsapp': false, // Enable WhatsApp notifications,
        ATT_status: 'authorized',
        loyalty_status: userInfo.code,
        Language_app: this.app.i18n.locale,
        Language_device: this.app.i18n.locale,
      },
    })
  }
  userSetData = (data) => {
    this.provide.profile.push({
      Site: { ...data },
    })
  }
  referralBannerEvent = (screen_type) => {
    this.provide.event.push('referral_banner_tapped', { screen_type })
  }
  downloadBannerEvent = ({ event, app_store }) => {
    this.provide.event.push(`download_banner_${event}`, { app_store })
  }
  firstTimePurchaseEvent = (packageDetails) => {
    this.provide.event.push('first_time_purchase', { ...packageDetails })
  }
  languageChangedEvent = ({ language_id }) => {
    this.provide.event.push('language_changed', { language: language_id })
  }
  referralProgramEnrollEvent = (code) => {
    this.provide.event.push('referral_program_enroll', { code })
  }
  purchaseFailedEvent = () => {
    this.provide.event.push('purchase_failed')
  }
  logoutEvent = () => {
    this.provide.event.push('logout')
  }
  viewItemListEvent = (value) => {
    this.provide.event.push('view_item_list', { ...value })
  }
  countrySearchedEvent = (keyword) => {
    this.provide.event.push('country_searched', { keyword })
  }
  searchClickEvent = ({ keyword, channel, searchType }) => {
    let title = 'country_searched'
    if (searchType === 'region') {
      title = 'region_searched'
    } else if (searchType === 'global') {
      title = 'global_searched'
    }

    this.provide.event.push(title, { keyword, channel })

    searchType === 'local' && this.userSetData({ searched: keyword })
  }
  esimChosenEvent = (title) => {
    this.provide.event.push(title)
  }
  googleSearchActionEvent = (query) => {
    this.provide.event.push('google_search_action_to_the_site', { query })
  }
  buyButtonTappedEvent = (value) => {
    this.provide.event.push('buy_button_tapped', { ...value })
  }
  viewItemEvent = (value) => {
    this.provide.event.push('view_item', { ...value })
  }
  transactionEvent = (sim) => {}
  myesimsPageViewEvent = () => {
    this.provide.event.push('myesims_page_view')
  }
  myesimsArchivedViewEvent = () => {
    this.provide.event.push('myesims_archived_view')
  }
  esimActionsViewEvent = () => {
    this.provide.event.push('esim_actions_viewed')
  }
  esimRenamedEvent = () => {
    this.provide.event.push('esim_renamed')
  }
  esimArchivedEvent = () => {
    this.provide.event.push('esim_archived')
  }
  esimRestoredEvent = () => {
    this.provide.event.push('esim_restored')
  }
  esimDeletedEvent = () => {
    this.provide.event.push('esim_deleted')
  }
  redeemedAirmoneyVoucherEvent = (value) => {
    this.provide.event.push('redeemed_airmoney_voucher', { ...value })
  }
  referralScreenViewedEvent = (code) => {
    this.provide.event.push('referral_screen_viewed', { code })
  }
  referAndEarnTappedEvent = () => {
    this.provide.event.push('refer_and_earn_tapped')
  }
  referralTermsConditionsTappedEvent = () => {
    this.provide.event.push('referral_terms_conditions_tapped')
  }
  referralShareOptionTappedEvent = (share_option) => {
    this.provide.event.push('referral_share_option_tapped', { share_option })
  }
  copyReferralCodeTappedEvent = () => {
    this.provide.event.push('copy_referral_code_tapped')
  }
  referralShowFullHistoryTappedEvent = () => {
    this.provide.event.push('referral_show_full_history_tapped')
  }
  referralLearnMoreTappedEvent = () => {
    this.provide.event.push('referral_learn_more_tapped')
  }
  purchaseEvent = ({ eventPostfix, value }) => {}
  transactionEcommerce = ({ ecommerce, typeClear = true }) => {}
  orderTransactionEcommerce = ({ ecommerce, typeClear = true }) => {}
  topupPurchaseEvent = (value) => {
    this.provide.event.push('topup_purchase', { ...value })
  }
  esimPurchaseEvent = (value) => {
    this.provide.event.push('esim_purchase', { ...value })
  }
  chargedEvent = (value) => {
    this.provide.event.push('Charged', { ...value })
  }
  viewShareEsimClickedEvent = () => {
    this.provide.event.push('view_share_esim_clicked')
  }
  loginEvent = (value) => {
    this.provide.event.push('login', value)
  }
  signupEvent = (value) => {
    this.provide.event.push('sign_up', value)
  }
  referralCodeAppliedEvent = (value) => {
    this.provide.event.push('referral_code_applied', { ...value })
  }
  signupStartedEvent = () => {
    this.provide.event.push('sign_up_started')
  }
  paymentPageViewEvent = () => {
    this.provide.event.push('payment_page_view')
  }
  addToCartEvent = ({ ecommerce }) => {}
  beginCheckoutEvent = (value) => {
    this.provide.event.push('begin_checkout', { ...value })
  }
  applyVoucherEvent = (value) => {
    this.provide.event.push('apply_voucher', { ...value })
  }
  referralCodeUsedEvent = (value) => {
    this.provide.event.push('referral_code_used', { ...value })
  }
  contactFormEvent = (formSent) => {
    const eventName = formSent ? 'contact_form_submit' : 'contact_form_view'

    this.provide.event.push(eventName)
  }
  esimDataUsageEvent = (data) => {
    this.provide.event.push('esim_data_usage', { ...data })
  }

  accountDeletionRequestSent = () => {
    this.provide.event.push('account_deletion_request_sent')
  }
  gotoEkycDetailButtonTapped = () => {
    this.provide.event.push('go_to_ekyc_detail_button_tapped')
  }
  renewalsUpdateEvent = (data) => {
    this.provide.event.push('renewal_status', { ...data })
  }
  testingEvent = () => {}
}
